import { Loading } from '@components/common'
import AvatarEditor from '@components/common/AvatarEditor'
import { Button, FormErrorMessageBox, Input } from '@components/ui'
import { CustomFieldType } from '@config/constants'
import {
  addCustomFieldSubmission,
  IUserFormResponse,
  IUserFormSubmission,
} from '@lib/services'
import { Field, Form, Formik } from 'formik'
import { AuthUser } from 'next-firebase-auth'
import Image from 'next/image'
import { useState } from 'react'
import * as Yup from 'yup'
import SubmitFormFile from './FormFileSubmit'

const getRefinedData = (data: IUserFormResponse[], userResponse) => {
  const originalUserData = data
    .filter((e) => !e.customField)
    .map((x) => {
      return {
        name: x.field,
        value: [
          x.field === 'ImageUrl' ? x.value ?? userResponse?.ImageUrl : x.value,
        ],
        customField: x.customField,
        customFieldId: x.customFieldId,
      }
    })

  const userFields = []
  for (const [key, value] of Object.entries(userResponse)) {
    const [name, customFieldId] = key.split(',')

    if (key !== 'ImageUrl') {
      userFields.push({
        name,
        value: Array.isArray(value) ? value : [value],
        customField: true,
        customFieldId,
      })
    }
  }

  return originalUserData.concat(userFields) as IUserFormSubmission[]
}

const UserForm = ({
  data,
  locale,
  currentUser,
  onDone,
  teamId,
}: {
  data: IUserFormResponse[]
  locale: string
  currentUser: AuthUser
  onDone: () => void
  teamId: string
}) => {
  // useEffect(() => {
  //   if (!data) {
  //     onDone()
  //   }
  // }, [data, onDone])
  const schemeMap = {}
  const initial = {
    ImageUrl: data?.find((obj) => obj?.field === 'ImageUrl')?.value ?? '',
  }
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  data
    ?.filter((x) => x.isRequired && x.customField)
    .forEach((e) => {
      schemeMap[`${e.field},${e.customFieldId}`] =
        e.customFieldType === 4
          ? Yup.array().min(1, 'At least one should be selected')
          : Yup.string().required('This Field is required')
    })
  const schema = Yup.object().shape({
    ...schemeMap,
    ImageUrl: Yup.string()
      .required('Profile Image is required!')
      .typeError('Profile Image is required!'),
  })

  data?.forEach((e) => {
    return (initial[`${e.field},${e.customFieldId}`] =
      e.customFieldType === 4 ? [] : e.value ?? '')
  })

  return data ? (
    <div>
      <Formik
        initialValues={initial}
        validationSchema={schema}
        onSubmit={async (values) => {
          setLoading(true)
          const refinedData = getRefinedData(data, values)
          try {
            const res = await addCustomFieldSubmission(
              currentUser,
              locale,
              refinedData,
              teamId
            )
            setDisabled(true)
            onDone()
          } catch (error) {
            setDisabled(false)
          }

          setLoading(false)
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {data?.map((e) => (
              <div key={e.field + e.customField.toString()}>
                {!e?.customField ? (
                  e?.field === 'ImageUrl' ? (
                    <div className="flex space-x-8 items-center">
                      <div>
                        <span className="font-semibold">Profile Image </span>
                        {!e.value && <sup style={{ color: 'red' }}>*</sup>}
                      </div>
                      {e.value ? (
                        <Image
                          className="rounded-full"
                          src={e.value}
                          alt="image url"
                          width={100}
                          height={100}
                        />
                      ) : (
                        <div className="w-44 relative">
                          <Field
                            name="ImageUrl"
                            as={AvatarEditor}
                            placeholder="Avatar"
                            currentUser={currentUser}
                            currentThumbnail={''}
                            onUploadSuccess={(fileURL: string) => {
                              setFieldValue('ImageUrl', fileURL)
                            }}
                            onRemoveSuccess={() => {
                              setFieldValue('ImageUrl', null)
                            }}
                          />
                          <span className=" label-text-alt text-error">
                            {
                              //@ts-ignore
                              errors.ImageUrl ?? ''
                            }
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    e.value && (
                      <div>
                        <span className="font-semibold">{e.field}</span>
                        <Field
                          type="text"
                          as={Input}
                          name={`${e.field},${e.customFieldId}`}
                          disabled
                          value={e.value}
                          className="mb-2 cursor-not-allowed "
                        />
                      </div>
                    )
                  )
                ) : (
                  <>
                    {e?.customFieldType === CustomFieldType.Text && (
                      <div className="flex flex-col my-3 relative">
                        <span className="font-semibold">
                          {e.field}{' '}
                          {e.isRequired && (
                            <sup style={{ color: 'red' }}>*</sup>
                          )}
                        </span>

                        <Field
                          type="textarea"
                          as={'textarea'}
                          name={`${e.field},${e.customFieldId}`}
                          placeholder="Your answer here..."
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                        />

                        <FormErrorMessageBox
                          name={`${e.field},${e.customFieldId}`}
                        ></FormErrorMessageBox>
                      </div>
                    )}
                    {e?.customFieldType === CustomFieldType.File && (
                      <div className="my-3 relative">
                        <span className="font-semibold">
                          {e.field}{' '}
                          {e.isRequired && (
                            <sup style={{ color: 'red' }}>*</sup>
                          )}
                        </span>
                        <Field
                          name={`${e.field},${e.customFieldId}`}
                          as={SubmitFormFile}
                          placeholder="Avatar"
                          currentUser={currentUser}
                          // currentThumbnail={''}
                          onUploadSuccess={(fileURL: string) => {
                            setFieldValue(
                              `${e.field},${e.customFieldId}`,
                              fileURL
                            )
                          }}
                          onRemoveSuccess={() => {
                            setFieldValue(`${e.field},${e.customFieldId}`, null)
                          }}
                        />

                        <FormErrorMessageBox
                          name={`${e.field},${e.customFieldId}`}
                        ></FormErrorMessageBox>
                      </div>
                    )}
                    {e?.customFieldType === CustomFieldType.Radio && (
                      <div className="my-3 relative">
                        <span className="font-semibold">
                          {e.field}{' '}
                          {e.isRequired && (
                            <sup style={{ color: 'red' }}>*</sup>
                          )}
                        </span>
                        {e.options.map((x) => (
                          <div key={x.id} className="flex items-center my-2 ">
                            <Field
                              type="radio"
                              name={`${e.field},${e.customFieldId}`}
                              className="radio radio-accent mr-2"
                              value={x.id}
                            />
                            {x.option}
                          </div>
                        ))}

                        <FormErrorMessageBox
                          name={`${e.field},${e.customFieldId}`}
                        ></FormErrorMessageBox>
                      </div>
                    )}
                    {e?.customFieldType === CustomFieldType.CheckBox && (
                      <div className="form-control relative">
                        <span className="font-semibold">
                          {e.field}{' '}
                          {e.isRequired && (
                            <sup style={{ color: 'red' }}>*</sup>
                          )}
                        </span>
                        {e.options.map((x) => (
                          <label
                            className="cursor-pointer flex items-center my-1"
                            key={x.id}
                          >
                            <Field
                              type="checkbox"
                              name={`${e.field},${e.customFieldId}`}
                              value={x.id}
                              className="checkbox checkbox-accent mr-2"
                            />

                            {x.option}
                          </label>
                        ))}

                        <FormErrorMessageBox
                          name={`${e.field},${e.customFieldId}`}
                        ></FormErrorMessageBox>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}

            <Button
              type="submit"
              className="mt-4"
              loading={loading}
              disabled={disabled}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <Loading />
  )
}

export default UserForm
