import { uploadFile } from '@lib/services/FileAssets'
import compressImage from '@lib/utils/compressImage'
import toaster from '@lib/utils/notify'
import {
  default as FilePondPluginFileValidateSize,
  default as FilePondPluginImageResize,
} from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import 'filepond/dist/filepond.min.css'
import { AuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { useTranslation } from 'react-i18next'

// Register the plugin
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform
)

type Props = {
  onUploadSuccess: Function
  onRemoveSuccess: Function
  currentThumbnail: string
  currentUser: AuthUser
}

const AvatarEditor: FC<React.PropsWithChildren<Props>> = ({
  currentUser,
  onUploadSuccess,
  onRemoveSuccess,
  currentThumbnail,
}) => {
  const [files, setFiles] = useState([])
  const router = useRouter()
  const locale = router.locale
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    if (currentThumbnail) {
      setFiles([
        {
          source: currentThumbnail,
          options: {
            type: 'local',
          },
        },
      ])
    }
  }, [currentThumbnail])

  const handleToRemoveImage = (errRes, file) => {
    setFiles([])

    if (errRes == null) {
      onRemoveSuccess()
    }
  }
  return (
    <div>
      {/* @ts-ignore */}
      <FilePond
        // @ts-ignore
        credits={false}
        allowMultiple={false}
        maxFiles={1}
        files={files}
        imagePreviewHeight={170}
        imageCropAspectRatio="1:1"
        // @ts-ignore
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        stylePanelLayout="compact circle"
        stylePanelAspectRatio="1:1"
        styleLoadIndicatorPosition="center bottom"
        onremovefile={(errRes, file) => handleToRemoveImage(errRes, file)}
        instantUpload={true}
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
        maxFileSize="1MB"
        labelIdle={`${t(
          'drag_drop_profile'
        )} <span class="filepond--label-action"> ${t(
          'file_browse_profile'
        )} </span>`}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file))
        }}
        onerror={(error: any) => {
          setFiles([])
          toaster.failure(`${error.main}. ${error.sub}`)
        }}
        server={{
          remove: null,
          revert: null,
          //processing a file
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            try {
              const compressedFile = await compressImage(file as File, 'avatar')
              const r = await uploadFile(
                locale,
                currentUser,
                1,
                compressedFile,
                true
              )

              load(r.url)
              if (onUploadSuccess) {
                onUploadSuccess(r.url)
              }
            } catch (e) {
              error(t('image_upload_error_msg'))
            }
            return {
              abort: () => {
                abort()
              },
            }
          },
          load: async (source, load, error, progress, abort, headers) => {
            await fetch(source)
              .then(async (r) => {
                load(await r.blob())
              })
              .catch((r) => error(r))
            // Should expose an abort method so the request can be cancelled
            return {
              abort: () => {
                abort()
              },
            }
          },
        }}
      ></FilePond>
    </div>
  )
}

export default AvatarEditor
