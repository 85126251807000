import { Config } from '@config/constants'
import { JoinLiveSessionResponse, LiveSession, SearchResult } from '@lib/types'
import Cookies from 'js-cookie'
import { AuthUser } from 'next-firebase-auth'
import qs from 'query-string'

const teamId = Cookies.get('team-id') ?? ''

export const getJWT = async (
  locale: string,
  currentUser: AuthUser,
  slug: string
): Promise<JoinLiveSessionResponse> => {
  const token = await currentUser.getIdToken()
  const res = await fetch(
    `${Config.API_BASE_URL}/api/LiveSession/join?roomSlug=${slug}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!res.ok) {
    throw await res.json()
  }
  const r = await res.json()

  return r
}

export const downloadSessionAttendanceDetail = async (
  locale: string,
  currentUser: AuthUser,
  slug: string,
  startTime
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(
    `${Config.API_BASE_URL}/api/sessions/${slug}/report/${startTime}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }
  )
  if (!res.ok) {
    throw await res.json()
  }
  const r = await res.text()
  return r
}

/** API for live session */

// add live Sessions
export const addLiveSession = async (
  locale: string,
  currentUser: AuthUser,
  data: any
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(`${Config.API_BASE_URL}/api/sessions`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  })
  if (!res.ok) {
    throw await res.json()
  }
  return await res.json()
}

// edit live session
export const editLiveSession = async (
  locale: string,
  currentUser: AuthUser,
  data: any,
  id: string
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(`${Config.API_BASE_URL}/api/sessions/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  })
  if (!res.ok) {
    throw await res.json()
  }
  return await res.json()
}

//enrollement live session

export const enrollmentLiveSession = async (
  locale: string,
  currentUser: AuthUser,
  data: any
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(`${Config.API_BASE_URL}/api/sessions/member`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  })
  if (!res.ok) {
    throw await res
  }
  return await res
}

//enrollment newlivesession
export const enrollmentNewLiveSession = async (
  locale: string,
  currentUser: AuthUser,
  data: any
) => {
  const token = await currentUser.getIdToken()
  const res = await fetch(`${Config.API_BASE_URL}/api/sessions/newMember`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  })
  if (!res.ok) {
    throw await res
  }
  return await res
}

// list live session
export const getAllLiveSessions = (
  locale: string,
  currentUser: AuthUser,
  page: number,
  sortValue: string,
  eventType?: string,
  scope?: string,
  enrollmentStatus?: string,
  isCancelled?: boolean,
  includePast?: boolean,
  search?: string,
  startFromDate?: string,
  endToDate?: string,
  freeOnly?: string,

  size?: number
): Promise<SearchResult<LiveSession>> =>
  currentUser.getIdToken().then((token) => {
    const sort = sortValue.split(':')
    const sortBy = sort[0]
    const sortType = sort[1]

    const queryString = qs.stringify(
      {
        ...(search && { search }),
        ...(enrollmentStatus && { enrollmentStatus }),
        ...(eventType && { eventType }),
        ...(scope && { scope }),
        ...(isCancelled && { isCancelled }),
        ...(includePast && { includePast }),
        ...(startFromDate && { startFromDate }),
        ...(endToDate && { endToDate }),
        ...(page && page > 0 && { page }),
        ...(size && size > 0 && { size }),
        ...(sortBy && { sortBy }),
        ...(sortType && { sortType }),
        ...(freeOnly && { freeOnly }),
      },
      { encode: false }
    )

    return fetch(`${Config.API_BASE_URL}/api/sessions?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        'x-team-id': teamId,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const getLiveSessionStatistics = (
  locale: string,
  currentUser: AuthUser,
  slug: String
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/sessions/${slug}/statistics`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const getSessionMembers = (
  locale: string,
  currentUser: AuthUser,
  slug: String,
  page: number,
  sortValue: string,
  search?: string
) =>
  currentUser.getIdToken().then((token) => {
    const sort = sortValue.split(':')
    const sortBy = sort[0]
    const sortType = sort[1]
    const queryString = qs.stringify(
      {
        ...(search && { search }),
        ...(page && page > 0 && { page }),
        ...(sortBy && { sortBy }),
        ...(sortType && { sortType }),
      },
      { encode: false }
    )
    return fetch(
      `${Config.API_BASE_URL}/api/sessions/${slug}/members?${queryString}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept-Language': locale,
        },
      }
    ).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const getLiveSessionsStatistics = (
  locale: string,
  currentUser: AuthUser,
  frequency: string
) =>
  currentUser.getIdToken().then((token) => {
    let url =
      frequency === 'All'
        ? `${Config.API_BASE_URL}/api/sessions/Statistics`
        : `${Config.API_BASE_URL}/api/sessions/Statistics?frequency=${frequency}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then(async (r) => {
      if (!r.ok) {
        throw await r
      }
      return r.json()
    })
  })

export const deleteLiveSessions = (
  locale: string,
  currentUser: AuthUser,
  id: string
) => {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/sessions/${id}/delete`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

/**Fetching details of live session */
export const getLiveSessionDetail = (
  locale: string,
  currentUser: AuthUser,
  slug: string
): Promise<LiveSession> => {
  return currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/sessions/detail/${slug}`
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
}

export const enrollLiveSessionClasses = (
  currentUser: AuthUser,
  locale: string,
  courseId: string,
  amount: number,
  token: string,
  paymentGateWay: 'esewa' | 'khalti'
) => {
  return currentUser.getIdToken().then((idToken) => {
    return fetch(`${Config.API_BASE_URL}/api/sessions/enroll`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + idToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({
        token,
        amount,
        liveSessionId: courseId,
        paymentGateWay: paymentGateWay === 'esewa' ? 6 : 2,
      }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const enrollLiveSessionFree = (
  currentUser: AuthUser,
  locale: string,
  liveSessionId: string
) => {
  return currentUser.getIdToken().then((idToken) => {
    return fetch(`${Config.API_BASE_URL}/api/sessions/enroll`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + idToken,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({ liveSessionId: liveSessionId }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })
}

export const searchLiveSessionModerators = (
  locale: string,
  currentUser: AuthUser,
  LiveSessionSlug: string,
  page: number,
  keyword: string,
  sortBy,
  sortType
) =>
  currentUser.getIdToken().then((token) => {
    const queryString = qs.stringify(
      {
        LiveSessionSlug: LiveSessionSlug ?? undefined,
        search: keyword ? keyword : undefined,
        ...(page && page > 0 && { page }),
        ...(sortBy && { sortBy }),
        ...(sortType && { sortType }),
      },
      { encode: false }
    )
    return fetch(`${Config.API_BASE_URL}/api/moderators?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const AddLiveSessionModerators = (
  locale: string,
  currentUser: AuthUser,
  LiveSessionSlug: string,
  EmailOrPhoneNumber: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/moderators`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
      body: JSON.stringify({
        EmailOrPhoneNumber,
        LiveSessionSlug,
      }),
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })

export const RemoveLiveSessionModerators = (
  locale: string,
  currentUser: AuthUser,
  id: string
) =>
  currentUser.getIdToken().then((token) => {
    return fetch(`${Config.API_BASE_URL}/api/moderators/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r
    })
  })

export const getSessionLessonResource = (
  locale: string,
  currentUser: AuthUser,
  liveSessionId: string,
  lessonId: string
) =>
  currentUser.getIdToken().then((token) => {
    let url = `${Config.API_BASE_URL}/api/sessions/${liveSessionId}/lesson`
    if (lessonId) {
      url += `?lessonIdentity=${lessonId}`
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept-Language': locale,
      },
    }).then((r) => {
      if (!r.ok) {
        throw r
      }
      return r.json()
    })
  })
